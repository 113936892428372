@for $i from 1 through 2 {
  $size: $i * 1.5rem;
  $x-size: $size * 0.5;
  .pl-#{$i} {
    padding-left: $size; }

  .pr-#{$i} {
    padding-right: $size; }

  .ml-#{$i} {
    margin-left: $size; }

  .mr-#{$i} {
    margin-right: $size; }

  .mt-#{$i} {
    margin-top: $size; }

  .mb-#{$i} {
    margin-bottom: $size; } }

body {
  max-width: 98%;

  @media screen and (min-width: 1441px) {
    max-width: 95%; }

  @media screen and (min-width: 1441px) {
    max-width: 90%; } }

.wrap {
  max-width: 98%;

  @media screen and (min-width: 1441px) {
    max-width: 90%; }

  @media screen and (min-width: 1441px) {
    max-width: 80%; } }




.nav {
  position: unset;
  top: unset;

  &-menu {
    max-width: unset; }

  &-float {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: baseline;
    width: 100%; }

  &-brand {
    font-size: 5em !important;
    line-height: 1.0;

    a {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: baseline;
      margin: auto;

      div {
        margin-left: 1rem;
        margin-right: 1rem; } }


    .nav-sub-brand {
      font-size: .6em !important;
      color: var(--theme); } }

  social {
    padding: 5px 25px;
    display: flex;
    flex-flow: row wrap;

    .social {
      line-height: 1.0;
      a {
        font-size: 1.2rem; }
      img {
        margin: 1px;
        max-height: 2rem; } } } }

.post {
  img:not(.icon) {
    @media screen and (min-width: 1025px) {
      width: unset;
      max-width: 1024px;
      max-height: 600px; }

    @media screen and (min-width: 1441px) {
      width: unset;
      max-width: 1440px;
      max-height: 800px; } }



  &_inner figure img:not(.icon) {
    margin-bottom: 0; }

  p, h1, h2, h3, h4, h5, h6, blockquote, ol, ul, hr {
    max-width: 1340px !important;
    margin-left: unset;
    margin-right: unset; }


  ul {
    list-style: disc;
    margin-left: 2em; }


  strong {
    font-weight: 900; }


  figure {
    display: inline-block;
    //margin: 10px
    figcaption {
      text-align: center;
      background-color: #222;
      color: #fff;
      font: italic smaller sans-serif;
      padding: 3px; } }

  #player {
    max-width: 1400px !important;
    min-width: 800px;
    float: left; }

  &_thumb {
    float: left; }


  &_downloads {
    min-width: 300px;
    float: left; }


  &_content {
    clear: left; }


  &_extra {
    float: right; } }

.subscribe {
  position: relative;
  cursor: pointer;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  & {
    width: 1.75rem;
    height: 1.75rem; }
  &::before {
    position: absolute;
    background: var(--theme);
    color: var(--light);
    opacity: 0;
    transition: opacity 0.25s ease-in; }

  &::before {
    content: attr(data-before);
    font-size: 0.8rem;
    width: 5.4rem;
    padding: 0.25rem;
    border-radius: 0.25rem;
    text-align: center;
    top: -2.25rem;
    right: 0; }

  &_done::before {
    content: attr(data-after); }

  &:hover::before {
    opacity: 1; } }

.article {
  &_thumb {
    height: 18rem;
    background-position: 50% 30%;
    @media screen and (min-width: 35rem) {
      height: 32.5rem; } }
  &_showcase &_thumb {
    height: 22rem; } }
